.phone_containerClass {
    height: 3em;
    width: 100%;
    position: relative;
    font-size: inherit;
}

.phone_containerClass .phone__inputClass {
    border: 1px solid #bdbdbd;
    border-bottom-width: 3px;
    border-radius: 3px;
    padding: 0.75em;
    padding-bottom: calc(0.75em - 4px);
    padding-left: 3rem;
    transition: border-color 0.3s;

    height: 3em;
    line-height: 1.5;
    font-size: 1em;

    width: 100%;
}

.phone__inputClass:focus {
    border-color: #2c98f0;
    outline: none;
}

.phone_containerClass .phone__buttonClass {
    border: 1px solid;
    border-bottom-width: 3px;
    background-clip: content-box;

    border-color: transparent !important;
}
.phone_containerClass .phone__buttonClass.open {
    background: none;
}
